.dtb-cases-header {
}

// tooltip overview sideffects
.tool-tip-item.side-effect .ant-tooltip-inner .item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80% !important;
}

.dtb-page-layout-with-nav {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: rgb(242, 244, 248) !important;
  }
}

.dtb-cases-search {
  max-width: 200;
  background: #f2f4f8;
  .ant-input {
    background: none;
  }
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  background: none;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background: none !important;
}
.comjoo-icon-pil {
  font-size: 24px;
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.dtb-username {
  margin: 0px 0px 8px 10px;
}
td.ant-table-cell {
  //padding: 10px 0px 2px 8px !important;
}
