.dtb-case-sidebar {
  .ant-descriptions-item-container {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .ant-descriptions-item:first-of-type .ant-descriptions-item-container {
    display: inline-block;
  }

  .dtb-select-medplan .dtb-selected-medplan .ant-descriptions-item-content {
    width: 100% !important;
    display: inline-block;
  }

  .ant-select-selection-item {
    overflow: hidden !important;
  }
  .ant-select {
    width: auto;

    margin: 0 !important;
    display: inline-block !important;
  }

  .ant-select-selection-selected-value {
    float: left;
    max-width: 100%;

    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-selection-item {
    float: left;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
