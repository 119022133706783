@media only screen and (min-width: 768px) {
  .dtb-content-wrapper-with-sidebar {
    display: flex;
    min-height: 0;

    .ant-layout,
    .ant-layout-content,
    .ant-layout-footer {
      background: none;
    }

    .dtb-content {
      flex-grow: 1;

      .dtb-detail-header {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0px;
        width: 100%;
        box-sizing: border-box;

        .dtb-detail-header-split-item:first-child {
          display: flex;
          padding: 0;
          width: 50%;
          box-sizing: border-box;
          padding-right: 7px !important;
          padding-left: 0px !important;

          .ant-card {
            width: 100%;
            margin: 0;
            box-sizing: border-box;
          }
        }

        .dtb-detail-header-split-item {
          width: 50%;
          display: flex;
          padding: 0;
          padding-left: 7px !important;

          .ant-card {
            width: 100%;
            margin: 0;
            margin-right: 0px; // reset left side
            box-sizing: border-box;
            padding: 0px 10px 10px 10px !important;
          }
        }
      }

      // detail inner tab
      .content-scroll-wrapper {
        //background: deepskyblue;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        padding: 0;
        margin: 14px 0px 0px 0px;
      }
    }
  }

  /**
   * @description inner layout detail 
   **/
  .dtb-page-content-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;

    .dtb-page-content-layout-nav {
      display: flex;
      flex-direction: row;
      .dtb-inner-content-nav {
        width: 100px !important;
        min-width: 200px;
        min-height: 0;

        .ant-menu {
          display: block;
          display: flex;
          height: 100%;
          overflow-y: scroll;
          min-height: 0;
        }
      }
    }
    .wow {
      overflow: hidden;
      flex: 1;
    }
    .wow,
    .content-wow {
      display: flex;
      flex-grow: 1;
      height: 100%;
      flex-direction: column;
    }

    .dtb-content-wrapper-with-sidebar {
      display: flex;
      overflow: hidden;
      flex-direction: row;
      height: 100%;

      .dtb-content {
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 10px 10px 0px 10px;
        padding: 0px 14px 0px 0px;
      }

      .dtb-case-sidebar {
        //s height: ;
        min-height: 0;
        // width: 100%;
        .dtb-scroll-content {
          /*
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          overflow: hidden;
          overflow-y: scroll;
          margin: 0px 0px 0px 0px;
          */
          display: block;
          height: 100%;
          width: 100%;
          overflow-y: scroll;

          .ant-card {
            width: 100%;
            margin: 0px 0px 14px 0px;
          }
        }

        .ant-card {
          width: 100%;
        }
      }
    }

    .dtb-page-content-layout-nav {
      float: left;
      display: flex;
      overflow: hidden;
      height: 100%;
      width: 200px;
    }

    .wow {
      display: flex;
      flex: 1;
    }

    .content-wow {
      padding: 14px 14px 0px 14px;
    }

    .head-wrapper .dtb-deail-header,
    a,
    .head-wrapper img {
      display: auto;
    }
  }
}
