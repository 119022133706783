@import '../../scss/variables';
@import '../../scss/mixins.scss';

.content-wrapper {
  @include page-content-wrapper;
  overflow-y: auto;
  margin-top: $space-m;
  padding: $space-m $space-l;

  .content-wrapper {
    padding-bottom: $space-m;
  }
}
