.ant-select-selection {
  background-color: transparent !important;
}

.menuuser {
  .ant-menu-title-content {
    height: 100%;

    display: block;
    height: 60px;
  }
  // position: fixed; // TODO not the best desition find more in ant
  //top: 0px;
  //right: 20px;

  z-index: 9999999;
  float: right;
  border-right: none;
  height: 64px;
  display: flex;
  align-items: center;

  .ant-btn-primary {
    width: 130px;
    font-size: 1rem;
  }

  li {
    float: right;
    background: none;
    height: 60px !important;
  }
}
.ant-select,
.ant-select-selector {
  position: relative;
  background-color: transparent !important;
  background: transparent !important;

  //border: 1px solid #f29663;
}

// language flags
.language-menu {
  display: block;
  background-color: transparent;
  text-align: right;
  margin-top: 20px;
  margin-right: 20px !important;
  .ant-select-selection-item {
    margin-top: 7px !important;
  }
  .ant-select-selector {
    position: relative;
    background-color: none !important;
    border: 1px solid #d9d9d9;
    border: 1px solid #f29663;
    border: 1px solid #f29663 !important;
    border: none;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 84px;
    height: 100%;
    border: 1px solid #f29663;
    border: 1px solid #f29663;
    align-items: center;
  }
  span {
    color: #fff;
    //margin: -4px 0px 0px 5px;
    //padding: 5px 5px 0px 0px;
  }
}

.language-item .flag-image,
.language-menu .flag-image {
  width: 30px;
  max-height: 30px;
  // line-height: 25px;
  margin-right: 5px;
  margin-top: -3px;
}
