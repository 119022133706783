.dtb-header {
  .dtb-head-wrapper {
    .dtb-logo-link {
      color: inherit;
      margin-top: 0px;
      float: left;
      .dtb-logo {
        margin-left: 20px;
      }
    }

    .dtb-header-subtitle {
      font-weight: bold;
      margin-left: 25px;
      text-transform: uppercase;
    }

    .dtb-planner-link {
      float: left;
      box-sizing: border-box;
      margin-left: 10px;
    }

    .dtb-language-switch {
      float: right;
      text-align: left;
    }

    .dtb-grettings {
      color: #f29663 !important;
    }
  }
}
