@import './../../../variables.scss';

.offcanvas {
  width: 470px;
  position: fixed;
  z-index: 999;
  top: 60px;
  right: -470px;
  height: calc(100% - 60px);
  overflow-y: scroll;
  padding: 10px;
  transition: width 2s;
  transition-timing-function: ease-in-out;
  transition: all 0.2s;
  background: #313237;
  background: #393a40;
  color: #fff;
  z-index: 99999999;
}

.open-canvas {
  transition: all 0.2s;
  transition-timing-function: ease-in;
  right: 0px !important;
}

#bgcanvas {
  right: -100%;
  position: fixed;
  z-index: 998;
  width: 100%;
  top: 60px;
  min-height: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.bgcanvasshow {
  position: fixed;
  z-index: 998;
  width: 100%;
  top: 60px;
  right: 0 !important;
  min-height: calc(100% - 70px);
  background: rgba(0, 0, 0, 0.5);
}
