@import '~antd/dist/antd';
//@import '~design-system/dist/variables';
@import './scss/mixins';
@import './scss/customFonts';
//@import './scss/theme';
// main layout
@import './dtb-init-loader.scss';
@import './main-grid.scss';
// inner layout
@import './content-grid-sidebar';
@import './content-grid-tab';
// organisms
@import './ant-card.scss';
@import './ant-skeleton.scss';
@import './ant-tab.scss';

.dtb-header {
  .dtb-header-subtitle,
  .language-menu,
  .dtb-grettings {
    display: none;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 10px !important;
  }
}

@media only screen and (min-width: 768px) {
  .ant-layout-content {
    .ant-menu {
      background: none;
      margin: 10px 0px 0px 0px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
  }

  .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
    height: 60px;
    span {
      color: #f29663;
    }
  }
  .ant-menu-title-content a {
    color: #f29663;
  }

  // clean

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  // sidebar
  .ant-layout-content {
    flex: auto;
    min-height: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    //padding: 10px;

    .dtb-scroll-content {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}

@media only screen and (min-width: 576px) {
  .dtb-header a:first-child {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .dtb-header a:first-child {
    display: inline-block;
  }
  .dtb-header {
    .dtb-header-subtitle {
      display: inline;
    }

    .language-menu {
      display: inline-block;
    }
  }
}

@media only screen and (min-width: 992px) {
  .dtb-header {
    a:first-child {
      display: inline-block;
    }
    .dtb-grettings {
      display: inline;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .dtb-header a:first-child {
    display: inline-block;
  }
}

.none-mobil {
  display: none;
}

.ant-layout-content .ant-layout {
  height: calc(100% - 3px);
}

.dtb-content-wrapper-with-sidebar {
  display: flex;
  flex: 1fr 280px;
  width: 100%;
  padding: 0;
  // background: darkgoldenrod;
  .dtb-scroll-content {
    width: 280px;
  }
  .dtb-sidebar {
    width: 280px;
    padding: 0;
    //  background-color: aquamarine;
  }
}

.about-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  //background-color: darkorchid;
  padding: 10px;
}
// here tabs
.wrapper-tab-content {
  min-height: 100%;
  overflow-y: scroll;
  // background: green;
  //padding: 20px;
}

.ant-list-item-meta {
  margin-top: 18px;
  .ant-list-item-meta-content {
    display: flex;
    align-items: center; /* <---- NEW    */
    height: 32px;
    line-height: 32px; /* same as height! */
    width: 100%;
    height: 100%;

    .ant-list-item-meta-title {
      width: 100%;
      height: 100%;
      height: 32px;
      // line-height: 32px; /* same as height! */
      padding-top: 6px;
    }
  }
}

.ant-table-tbody {
  overflow: auto scroll;
  //overflow-x: scroll !important;
}

.wrapper-tab-content,
#scroll-content {
  width: 100% !important;
  overflow-x: hidden !important;
  .medication-wrapper {
    width: 100%;
    overflow-x: hidden;
  }
}
.ant-table {
  overflow: auto;
}

.dtb-treatment .ant-table-cell,
.dtb-treatment .ant-table-cell {
  word-wrap: normal;
  white-space: normal !important;
}

.ant-tag,
.item-name,
.rounded-color-state {
  float: left;
  text-align: left;
}
.tool-tip-item.side-effect {
  overflow: hidden;

  .ant-tooltip-inner,
  .ant-tooltip-arrow::before {
    background-color: $white;
  }

  .ant-tooltip-inner {
    border: 1px solid $grey-light;

    .rounded-color-state {
      @include rounded-tage-state;
      margin-right: 5px;
    }

    .item-name {
      @include text-ellipsis;
      width: 89%;
    }

    .rounded-color-state.side-effect-state,
    .side-effect-state + .item-name {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.ant-tabs-tab .ant-badge-not-a-wrapper {
  position: relative;
  top: -8px;
  left: 0px;
}

.dtb-header a {
  color: #f29663 !important;
}

.dtb-header a:hover {
  color: #fff !important;
}
.ant-table-expanded-row-fixed {
  padding: 0 !important;
  margin: 0;
}
.dtb-warnings-grid {
  margin: 0;
  padding: 0;
  background-color: #f76a6a;

  .dtb-warning-icon {
    margin: 10px;
    display: inline-block;
  }
  .dtb-warnings {
    color: #fff;
    border-bottom: solid 1px #ccc;
  }
  .dtb-warning-baneer-close {
    float: right;
    margin: 10px;
  }
}
