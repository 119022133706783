@media only screen and (min-width: 768px) {
  html,
  body {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  #root {
    overflow: hidden;
  }

  .dtb-layout {
    grid-column: 1;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    width: 100%;
    min-height: 100%;
    height: 100% !important;
    box-sizing: border-box;
    .dtb-header {
      height: 60px;
      width: 100%;
      color: #f29663;
      background: #753055 !important;
      position: fixed;
      z-index: 800;
      top: 0;
      grid-column: 1;
      grid-row: 3;
      padding: 0;
      line-height: 60px;

      a:first-child {
        display: none;
      }

      .ant-menu,
      .none-mobil {
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
      .dtb-header-subtitle {
        display: inline-flex;
        display: block;
      }

      .trigger,
      .trigger-two {
        display: none;
      }

      .head-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .ant-menu-horizontal {
          justify-content: flex-end !important;
        }
      }

      .ant-menu,
      .ant-menu li {
        height: 60px;
        padding: 0;
        overflow: hidden;
        background: darkmagenta;
        background: none;
        display: inline-block;
        border-bottom: none;
        margin-top: -1px;
      }

      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
        margin-top: -1px;
        margin-bottom: 0;
        padding: 0 20px;
      }
    }
  }

  .dtb-main-content {
    grid-column: 1;
    grid-row: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
}
